import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/registro",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/missolicitudes",
    name: "myrequests",
    component: () =>
      import(/* webpackChunkName: "myrequests" */ "../views/MyRequests.vue"),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/encuesta/:id_ticket/:token",
    name: "encuesta",
    component: () =>
      import(
        /* webpackChunkName: "encuesta" */ "../views/FormEncuestaServicio.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitud",
    name: "newrequest",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/NewRequest.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitud/casasegura",
    name: "safehouserequest",
    component: () => import("../views/SafeHouseRequest.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitud/permisoCirculacion",
    name: "solicitudPermisoCirculacion",
    component: () =>
      import(
        /* webpackChunkName: "solicitudPermisoCirculacion" */
        "../views/TransitRequest.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitud/arbolado",
    name: "solicitudArbolado",
    component: () =>
      import(
        /* webpackChunkName: "solicitudArbolado" */
        "../views/TreeRequest.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitud/postulacionFeria",
    name: "solicitudFeriaEmprendimiento",
    component: () =>
      import(
        /* webpackChunkName: "solicitudFeriaEmprendimiento" */
        "../views/FeriaRequest.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitud/:service_id",
    name: "newrequestservice",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/NewRequest.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/solicitudfecha/:service_id",
    name: "newdate",
    component: () =>
      import(/* webpackChunkName: "newdate" */ "../views/NewDate.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/versolicitud",
    name: "requestdetails",
    component: () =>
      import(
        /* webpackChunkName: "requestdetails" */ "../views/RequestDetails.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/versolicitud",
    name: "safehouse_requestdetails",
    component: () =>
      import(
        /* webpackChunkName: "requestdetails" */ "../views/SafeHouseRequestDetails.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/micuenta",
    name: "myaccount",
    component: () =>
      import(/* webpackChunkName: "myaccount" */ "../views/MyAccount.vue"),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/contrasena/recuperar",
    name: "forgottenpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgottenpassword" */ "../views/ForgottenPassword.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/contrasena/crear",
    name: "resetpassword",
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "../views/ResetPassword.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/reporta/electricidad",
    name: "street_light",
    component: () =>
      import(
        /* webpackChunkName: "streetlight" */ "../views/StreetLightRequest.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: false }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Store.state.logged;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const visibleAuth = to.matched.some(record => record.meta.visibleAuth);
  if (requiresAuth && !isAuthenticated) {
    next({ name: "login", query: { redirect: to.name } });
  } else if (isAuthenticated && !visibleAuth) {
    next({ name: from.name ? from.name : "home" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Store.state.logged;
  if (isAuthenticated) {
    if (Date.now() - Date.parse(Store.state.user.created) > 1199999) {
      Store.dispatch("clearCredentials");
      next({
        name: "login",
        query: {
          redirect: to.name,
          timeout: "true"
        }
      });
    }
  }
  next();
});

export default router;
