import Vue from "vue";

import "./styles/quasar.styl";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";

import {
  Quasar,
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QAvatar,
  QTabs,
  QTab,
  QRouteTab,
  QFooter,
  QCard,
  QCardSection,
  QCardActions,
  QTable,
  QTh,
  QTr,
  QTd,
  QDialog,
  QForm,
  QInput,
  QToggle,
  QSelect,
  QBadge,
  QBtnDropdown,
  QSeparator,
  QExpansionItem,
  QScrollArea,
  QRadio,
  ClosePopup,
  QLinearProgress,
  Ripple,
  Loading,
  QRating,
  Notify,
  QCheckbox,
  QChip,
  QDate,
  QPopupProxy
} from "quasar";

Vue.use(Quasar, {
  config: {
    loading: {
      /* Loading defaults */
    },
    Notify: {}
  },
  components: {
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QAvatar,
    QTabs,
    QTab,
    QRouteTab,
    QFooter,
    QCard,
    QCardSection,
    QCardActions,
    QTable,
    QTh,
    QTr,
    QTd,
    QDialog,
    QForm,
    QInput,
    QToggle,
    QSelect,
    QBadge,
    QBtnDropdown,
    QSeparator,
    QExpansionItem,
    QScrollArea,
    QRadio,
    QRating,
    QLinearProgress,
    QCheckbox,
    QChip,
    QDate,
    QPopupProxy
  },
  directives: { ClosePopup, Ripple },
  plugins: { Loading, Notify }
});
